.all {
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    background-color: #ec3237;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 0px;
}

.all .content {
    width: 50%;
    height: 100%;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid rgb(209, 209, 209);
    padding: 20px 10px 20px 10px;  
    background-color: #fcfcfc;
    gap: 20px;
}
.all .content p{
    color: gray;
    font-weight: 500;
    text-align: justify;
}
.all .content li{
    color: gray;
    font-weight: 500;
    font-size: 14px;
}
.all .content a{
    text-decoration: none;
    color: #d41f28;
}
.all .content hr {
    margin-top: 40px;
    margin-bottom: 20px;
}
.all .content button{
    background-color: #ec3237;
    color: white;
    font-weight: 700;
    font-size: 15px;
    width: 100%;
    height: 45px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: 1s;
}
.all .content button:hover{
    background-color: #c0272c;
}
.logo {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo img{
    height: 100%;
}

@media (max-width: 900px) {
    .all .content {
        width: 85%;
        height: 90%;
        border: 1px solid rgb(209, 209, 209);
        padding: 10px 20px 10px 20px;
    }
    .logo {
        height: 150px;
        width: 100%;
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}