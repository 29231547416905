.all {
    height: 100vh;
    overflow-y: auto;
    background-color: #f5f5f5;
}

.contentForm {
    background-color: gray;
}
.imgBanner {
    position: relative;
    width: 100%;
    height: 350px;
}

.imgBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem cubra todo o espaço do contêiner */
}

.Textimgbanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ec32384d; /* Cor de fundo com 50% de opacidade */
    padding: 20px;
    box-sizing: border-box; /* Inclui o padding na largura e altura do elemento */
    text-align: center;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.Textimgbanner h1 {
    font-size: 35px;
    font-weight: 600;
    font-weight: bold;
}

.Textimgbanner text {
    font-size: 18px;
    font-weight: 500;
    text-align: justify;
    width: 60%;
}

@media (max-width: 600px) {
    .Textimgbanner text {
        font-size: 14px;
        font-weight: 500;
        text-align: justify;
        width: 90%;
    }
    .Textimgbanner h1 {
        font-size: 28px;
        font-weight: 600;
        font-weight: bold;
    }
}

.form {
    background-color: transparent;
    position: absolute;
    top: 300px;
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.form .successMensage{
    width: 80%;
    height: 400px;
    background-color: rgb(255, 255, 255);
    border-bottom:solid 6px #d41f28;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    color: greenyellow;
    font-size: 200px;
}