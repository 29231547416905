.header {
    background-color: #ec3237;
    padding: 0px;
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.header img{
    width: 100px;
    padding-left: 30px;
    cursor: pointer;
}

.header a {
    border: 1px none #f7a739;
    background-color: #ffde59;
    color: white;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;
    padding: 12px 18px;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 600;
    transition: background-color .2s;
    display: block;
    margin-right: 30px;
    text-decoration: none;
    cursor: pointer;
    transition: 1s;
}

.header a:hover {
    -webkit-box-shadow: 0px 0px 15px -10px rgba(112, 9, 9, 0.75);
    box-shadow: 0px 0px 15px -0px rgba(143, 17, 17, 0.75);
}

@media (max-width:600px) {
    .header {
        background-color: #ec3237;
    }
    .header a {
        padding: 10px 13px;
    }
}