.all {
    height: 100vh;
    overflow-y: auto;
    background-color: #f5f5f5;
}

.contentForm {
    background-color: gray;
}
.imgBanner {
    position: relative;
    width: 100%;
    height: 350px;
}

.imgBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem cubra todo o espaço do contêiner */
}

.Textimgbanner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ec32384d; /* Cor de fundo com 50% de opacidade */
    padding: 20px;
    box-sizing: border-box; /* Inclui o padding na largura e altura do elemento */
    text-align: center;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.Textimgbanner h1 {
    font-size: 35px;
    font-weight: 600;
    font-weight: bold;
}

.Textimgbanner text {
    font-size: 18px;
    font-weight: 500;
    text-align: justify;
    width: 60%;
}

@media (max-width: 600px) {
    .Textimgbanner text {
        font-size: 14px;
        font-weight: 500;
        text-align: justify;
        width: 90%;
    }
    .Textimgbanner h1 {
        font-size: 28px;
        font-weight: 600;
        font-weight: bold;
    }
}

.form {
    background-color: transparent;
    position: absolute;
    top: 300px;
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.form form{
    width: 80%;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-bottom:solid 6px #d41f28;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.form form div {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.form form p{
    font-size: 18px;
    font-weight: 600;
    color:#7c7c7c;
}

.form label {
    width: 100%;
    font-weight: bold;
    margin-top: 10px;
    color: rgb(180, 180, 180);
}

.form input {
    margin-top: 5px;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    transition: border-bottom-color 0.3s;
    font-size: 16px;
}

.form input:focus {
    outline: none;
    border-bottom-color: #ec3237; /* Cor quando em foco */
}

.form select {
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #ccc;
    transition: 1s;
    color: gray;
}

.form select:focus {
    outline: none;
    border-bottom-color: #ec3237;
}

.form button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ec3237;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: 1s;
}

.form button:hover {
    background-color: #d41f28; /* Cor quando em hover */
}

@media (max-width: 600px) {
    .form form{
        width: 90%;
        height: auto;
        background-color: rgb(255, 255, 255);
    }    
    .form form div {
        width: 90%;
    }
    .form button {
        margin-top: 40px;
        padding: 8px 17px;
        border: none;
        border-radius: 5px;
        background-color: #ec3237;
        color: white;
        font-size: 13px;
        cursor: pointer;
    }
}

.customFileInput {
    color: #7c7c7c;
    max-width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 17px;
    background-color: rgb(226, 226, 226);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 1s;
}

.customFileInput:hover {
    background-color: #414141;
    color: #FFF;
}

/* Oculte o input de arquivo */
input[type="file"] {
    display: none;
}

.RenderFileInput {
    max-width: 180px;
    min-height: 180px;
    max-height: 190px;
    display: flex;
    border: solid 3px rgb(206, 206, 206);
    border-radius: 50%;
}

.alunoInfo {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.alunoInfo h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #ec3237;
}

.alunoInfo p {
    margin: 5px 0;
    font-size: 16px;
}

.searchWrapper {
    text-align: center;
    padding: 20px;
    background-color: #f0f4f8;
}

/* Estilos atualizados para o campo de pesquisa */
.searchWrapper {
    padding: 40px 0px;
    background-color: #f7f9fc;
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 25px;
}

.searchBarContainer {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.searchBarInput {
    width: 100%;
    padding: 15px 20px 15px 70px; /* Aumentado para 70px */
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 50px;
    outline: none;
    transition: border-color 0.3s ease;
}

.searchButton {
    position: absolute;
    top: 10%;
    right: 0px;
    transform: translateY(-50%);
    color: #999;
    width: 20px;
    height: 40px;
    background-color: transparent;
    cursor: pointer; /* Adicione cursor para indicar interatividade */
}

.searchIcon {
    position: absolute;
    top: 20%;
    right: 0px;
    transform: translateY(-40%);
    color: #999999;
    width: 20px;
    height: 40px;
    cursor: pointer; /* Adicione cursor para indicar interatividade */
}

.searchIcon:hover {
    color: #ec3237;
}


.searchBarInput:focus {
    border-color: #007BFF;
}

.searchBarInput::placeholder {
    color: #999;
}

.searchBarButton {
    display: none; 
}

@media (max-width: 600px) {
    .searchWrapper {
        padding: 20px 10px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .searchBarInput {
        padding: 12px 15px 12px 55px; /* Aumente o padding-left */
        font-size: 14px;
    }

    .searchIcon {
        right: 0px;
        top: -25%;
        height: 100%;
    }
}
